@import url('../../../../assets/css/style.css');

.main-wrapper {
    padding-top: 70px;
}

.lesson-row {
    align-items: stretch; 
    height: 100vh; /* Full viewport height */
}

.lesson-content-card {
    background-color: #d1d7dc;
    height: 100%; /* Fill available height */
    display: flex; /* Use flexbox for alignment */
    flex-direction: column; /* Arrange children in a column */
    overflow: hidden; 
}



.lesson-widget-group {
    flex-grow: 1; /* Allow this to grow and fill available space */
    overflow-y: auto; /* Enable vertical scrolling if necessary */
}

.lesson-content-card, 
.lesson-introduction {
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure both cards fill the height */
}

.video-container {
    position: relative;
    width: 100%;
    height: 100%; /* Ensure video container fills parent */
}

/* Ensure the video fills the container without scrolling */
.video-container video {
    max-width: 100%; /* Full width */
    height: auto; /* Automatically adjust height to maintain aspect ratio */
}


.lesson-widget-group img {
    max-height: 900px; /* Limit maximum height */
    max-width: 100%; /* Ensure the image fits within the container's width */
    width: 100%; /* Set the image width to 100% of its container */
    height: auto; /* Adjust height automatically */
    object-fit: contain; /* Ensure the entire image is visible */
    margin: 0 auto; /* Center the image */
    display: block; /* Ensure margin auto works */
}


/* Disable pointer events for the next control apart from the button icon */
.carousel-control-prev, .carousel-control-next {
  pointer-events: none;
}

.carousel-control-next button {
  pointer-events: auto;
  display: inline-block;
}


.rich-text-content {
    max-height: 300px; /* Limit height for rich-text */
    overflow-y: auto; /* Enable scrolling if content is too long */
    padding-right: 150px;
    padding-left: 150px;
}


  
  

